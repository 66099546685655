<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">MCR/MCG 작성 안내</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="txt-center pt-30 pb-30">

                    본인 산하 직원의 MCR/MCG를 작성해 주세요.<br>
                    MCR/MCG 는 <span class="underline">직원 별 월1회 작성</span> 가능합니다.

                    <div class="mt-20">
                        <span @click="pop.goLinkPage" class="btn-view">팀 평가 페이지로 바로 가기</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router';

export default {
    name: 'MCRMCGAddInfoPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const router = useRouter();

        const pop = reactive({
            goLinkPage : () => {
                sessionStorage.setItem('gISF', 'Y');
                emit('close');
                router.push({ 
                    name:'myPIMS-TeamEvaluationManagementList' 
                });
            },

            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>